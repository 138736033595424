// COMPONENTS
import React from 'react'
import ListingsPage from 'templates/ListingsPage'
// CONTENT
import content from 'content/rfJobs.yml'

// RFJobs page
const RFJobsPage = () => {
  return <ListingsPage content={content} />
}

export default RFJobsPage
