import { tsFetch } from 'apis/TSFetch'
import gsdbAPI from 'apis/GSDB/APIMap'
import GatsbyConfig from 'root/gatsby-config'

const saveSearch = async (query, list) => {
  if (query && list) {
    const { docUrl } = GatsbyConfig.siteMetadata.gsdb
    return tsFetch(
      gsdbAPI,
      'gsdb',
      'newRow',
      { sheetTitle: list },
      null,
      {
        docUrl,
        data: {
          created_ts: new Date().getTime(),
          created_date: new Date(),
          query
        },
      },
      null,
      null
    )
  }
}

// Performs job search on job category JSON data
const searchJobs = async (category, query)=> {
  const propsToSearch = ['title', 'subtitleHTML']
  console.log(`Searching for ${query}...`)

  // Performs regex test on each of the job properties, returns true if match
  const isMatch = (query, job) => {
    const {meta} = job
    if (new RegExp(`${query}`,'gi').test(meta[0]))    // Tests location field only
      return true
    
    for( let i = 0; i < propsToSearch.length; i++){
      // console.log(`${propsToSearch[i]} : ${job[propsToSearch[i]]}`)
      if (new RegExp(`${query}`,'gi').test(job[propsToSearch[i]]))
        return true
    }
  }
  // Store query in google sheets
  await saveSearch(query, 'rfjobs-queries')

  // REMEMBER:  This is the formatted job object, and not original raw format
  // Iterate through all subcategories and perform search on job parameters
  const jobs = category.reduce( (results, job) => {
    if(isMatch(query, job))
      results.push(job)
    return results
  }, [])
  return { query, count: jobs.length, jobs }
}

export { searchJobs }