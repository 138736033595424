// COMPONENTS
import React, { useContext } from 'react'
// import { Link } from 'gatsby'
import { ThemeContext } from 'layout/ThemeProvider'
import ContentContainer from 'layout/ContentContainer'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Container from 'react-bootstrap/Container'
// CUSTOM COMPONENTS
// import Icon from 'elements/Icon'
// LOGIC
// import { handleEnterKey } from 'apis/Browser'

// Link list component
const LinkList = ({ title, listings, id = 'link-list', linkCB = null }) => {
  const { invertedTheme } = useContext(ThemeContext)

  return (
  <ContentContainer type='body'>
    <Row className='justify-content-center' >
      <Col id={id} xs={12}>
        <Row>
          <Col xs={12}>
            <h3>{title}</h3>
          </Col>
          { (listings.length > 0) && listings.map( listing => (
            <Col md={6} xs={12} key={listing.name}>
              { listing.name && listing.url ?
                <a href={listing.url} onClick={() => linkCB()}>
                  <p className={`py-0 my-0 text-${invertedTheme}`}>{listing.name}</p>
                </a> : <p>{listing.name}</p>
              }
              {/* { listing.links && listing.links.map( link => (
                <a href={link.url} target='_blank' rel='noreferrer'>
                  <p className={`py-0 my-0 text-${invertedTheme}`} >{link.title}</p>
                </a> 
              ))} */}
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  </ContentContainer>
  )
}

export default LinkList