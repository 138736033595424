// COMPONENTS
import React, { useEffect, useState } from 'react'
import Layout from 'layout/Layout'
import SEO from 'layout/SEO'
// BOOTSTRAP
// SECTIONS
import Hero from 'LandingPage/Hero'
import Blurb from 'LandingPage/Blurb'
import LinkList from 'components/LinkList'
import CardListing from 'components/Item/CardListing'
// import Banner from 'LandingPage/Banner'
// import Socials from 'LandingPage/Socials'
import SearchBar from 'components/Search/SearchBar'
// USER SIGNUP/CONTACT
import SignUp from 'components/SignUp'
import SimpleSignUp from 'components/SignUp/simple'
// import MessageDrop from 'components/SignUp/MessageDrop'
// LOGIC / API
import { getUrlParams } from 'apis/Browser'
import { getJobs } from 'apis/RFJobs'
import { searchJobs } from 'apis/RFJobs/search.js'

// Listing page template
const ListingsPage = ({ content }) => {
  const {
    seo,
    hero,
    blurb,
    search,
    layout,
    listings,
    signup,
    stickySignup,
  } = content

  const [urlParams, setUrlParams] = useState({s: 'optics'})
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [initLoad, setInitLoad] = useState(true)
  const [jobData, setJobData] = useState(null)
  const [cards, setCards] = useState(null)
  const [query, setQuery] = useState('')

  const onCategoryClick = () => {
    setLoading(true)
    setCards(null)
  }

  const jobSearch = async (query) => {
    setLoading(true)
    setSearching(true)
    setQuery(query.query)
    if (jobData.length > 0){
      const results = await searchJobs(jobData, query.query)
      // console.log({jobs})
      setCards({ postCount: results.count, formatted: results.jobs})
    }
    setLoading(false)
  }

  // Fetch information on load
  useEffect(() => {
    const fetchData = async () => {
      setInitLoad(false)
      setLoading(false)
      let urlParams = getUrlParams()
      urlParams = (!urlParams.s) ? { s: 'antennas'} : urlParams
      search.title = `search ${urlParams.s} listings`
      // console.log({urlParams, loading})
      const jobData = await getJobs(urlParams)
      setJobData(jobData)
      setUrlParams(urlParams)
      setCards({postCount: jobData.length, formatted:jobData})
    }
    if (loading || initLoad)    
      fetchData()
  }, [loading, initLoad, search])
  
  return (
    <Layout header={layout.header} stickyFooter={stickySignup && <SimpleSignUp content={stickySignup} />}>
      <SEO title={seo.title} image={seo.imageUrl} />
      { hero && <Hero content={hero} /> }
      { blurb && <Blurb content={blurb} /> }
      { search && <SearchBar content={search} searchCB={ q => jobSearch(q) } /> }
      { listings && <LinkList title='select category' listings={listings} linkCB={ () => onCategoryClick() }/> }
      <CardListing 
        content={cards} 
        isLoading={loading}
        isSearching={searching}
        searchErrorMsg={`Scud... No results for ${query}.`}
        altCountHeading={`${urlParams.s || ''} jobs found`} />
      { signup && <SignUp content={signup} /> }
    </Layout>
  )
}

export default ListingsPage
