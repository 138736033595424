import { format } from 'timeago.js'

// OLD SHIT.  Replace with below code once you are using google jobs format.
// const formatPosts = (posts) => {
//   let formatted = posts.map( post => {
//     let { jobId, title, date, place, company, description, descriptionHTML, link, applyLink, senorityLevel, employmentType } = post
//     let formattedDate = format(date)
//     // console.log(post)
//     return {
//       title: `${company} - ${title}`,
//       subtitle: description,
//       subtitleHTML,
//       formattedDate,
//       path: jobId,
//       image: null,
//       // image: ( images && images.length > 0) ? images[0] : null,
//       meta: [ 
//         {text: place}, 
//         {text: formattedDate}, 
//         {text: `${employmentType} ${(senorityLevel !== 'Not Applicable') ? senorityLevel : '' }`}, 
//         {text: 'apply', url: '' }
//       ]
//     }
//   })
//   return { postCount: posts.length, formatted}
// }

// Formats job listings for Card format
const formatPosts = (rawJobPosts) => {
  const {subcategories} = rawJobPosts
  return subcategories.reduce((prev, curr) => {
    // console.log({prev, curr, categoryJobCount: curr.length})
    const {jobs} = curr
    let fJobs = jobs.map(job => {
      let { id, title, company, description, location, icon, companyLink, applyLink, metadata } = job
      // console.log({ jobId, title, company, description, location, logo, links, applyLinks, meta })
      // TEMPORARY HACKS UNTIL WE CAN CLEAN DATA
      // description = description.replaceAll(/\n/g,'<br/>')
      // company = company.replaceAll(/&amp;/g, '&')
      // title = title.replaceAll(/&amp;/g, '&')
      const randId = () => { return Math.floor(Math.random() * 10000) }  
      return {
        id: id ? id : randId(),
        title: `${company} - ${title}`,
        subtitleHTML: description,
        path: companyLink,
        // path: (companyLink) ? companyLink : ``,    // Hack for now
        // path: (companyLink) ? companyLink : `#${randId()}`,    // Hack for now
        icon: icon ? icon : '/favicon-32x32.png',
        // image: ( images && images.length > 0) ? images[0] : null,
        meta: [ 
          {text: location, badge: 'badge-dark'},
          {text: format(parseInt(metadata.posted)*1000), badge: 'badge-dark'}, 
          {text: `${metadata.salary ? metadata.salary : '' }`, badge: 'badge-secondary'}, 
          {text: `${metadata.partTime ? metadata.partTime : ''}`, badge: 'badge-secondary'}, 
          {text: `${metadata.fullTime ? metadata.fullTime : ''}`, badge: 'badge-secondary'}, 
          // {text: `${meta.employmentType} ${(senorityLevel !== 'Not Applicable') ? senorityLevel : '' }`}, 
        ],
        footerMeta: [
          {text: 'back to top', badge: 'badge-primary', url:'#link-list', extUrl: false },
          {text: (applyLink) ? 'apply' : '', badge: 'badge-info', url: (applyLink.url) ? applyLink.url : `#${randId()}`, extUrl: true }
        ]
      }
    })
    prev.push(...fJobs)
    return prev
  },[])
}

export { formatPosts }