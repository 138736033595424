// import { getUrlParams } from 'apis/Browser'
import { fetchContent } from 'apis/FetchContent'
import { formatPosts } from './postFormatter'


const isDev = false      // Set to true to use DEV data on S3.  Make sure to set to false on deploy!
const s3Sources = {
  antennas:   `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}antennas.json`,
  asics:      `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}asics.json`,
  // control:    `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/.json`,
  circuits:   `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}circuits.json`,
  comm:       `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}communications.json`,
  optics:     `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}optics.json`,
  research:   `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}research.json`,
  software:   `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}software.json`,
  technical:  `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}technical.json`,
  testing:    `https://neoevolutions.s3-us-west-2.amazonaws.com/rfjobs/${isDev?'DEV/':''}testing.json`
}

// Select random property from object
const randomProperty = (obj) => {
  const keys = Object.keys(obj)
  return obj[keys[ keys.length * Math.random() << 0]]
}

// Fetches RF jobs listings from AWS/S3
const getJobs = async (params) => {
  // console.log({s3Sources, params, dataUrl, jobs})
  const dataUrl = (params && params.s) ? s3Sources[params.s] : randomProperty(s3Sources)
  // console.log({params, dataUrl})
  let rawJobs = (dataUrl) ? await fetchContent(dataUrl, 'json') : null
  return (rawJobs.totalCount > 0) ? formatPosts(rawJobs) : null
}

export { getJobs }