// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import MarkdownBody from 'elements/MarkdownBody'
import Spinner from 'layout/Spinner'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
// HOOKS
import { useExternalContent } from '../hooks/useExternalContent.js'
// NPM
import { format } from 'timeago.js'


// Blurb component
const Blurb = ({ content }) => {
  const cont = useExternalContent( content )
  if (cont) {
    const { section, section: {inverted}, blurbs } = ( cont.isExtContent ) ? cont.blurb : cont
    const { title, bodyMd, author, date } = (blurbs.length > 0) ? blurbs[0] : null 
    return (
      <Section content={section}>
        <Container fluid>
          <Row className='py-4'>
            <Col className='mb-4' xs={12}>
              { title && <h1 className='display-3'>{title}</h1> }
            </Col>
            <Col className='mb-4' xs={12}>
              { bodyMd && <MarkdownBody markdown={bodyMd} inverted={inverted} /> }
            </Col>
            <Col className='mb-4' xs={12}>
              { author && <h6>{author}</h6> }
              { date && <small>{format(date)}</small> }
            </Col>
          </Row>
        </Container>
      </Section>
    )
  } else {
    return(
      <Container className='py-5' fluid>
        <Spinner name='PuffLoader' size='100px' />
      </Container>
    )
  }
}

export default Blurb
